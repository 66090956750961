import type { Schemas } from "#shopware";

interface CacheEntry {
  id: string;
  productPromise: Promise<Schemas["Product"]>;
}

const cache: CacheEntry[] = [];

export const useCachedProductSearch = () => {
  const { search: shopwareSearch } = useProductSearch();

  const search = async (id: string) => {
    const cachedResult = cache.find((x) => x.id == id);
    if (cachedResult) return await cachedResult.productPromise;

    const productPromise = shopwareSearch(id).then(
      (resp) => resp.product as Schemas["Product"],
    );

    cache.push({ id, productPromise });

    return await productPromise;
  };

  return { search };
};
