<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getTranslatedProperty, getProductRoute } from "@shopware/helpers";

const { search } = useCachedProductSearch();
const { t } = useI18n();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const props = defineProps<{
  shopwareId: string;
  position: string;
  nextPossibleDeliveryDate: string;
}>();
const { shopwareId } = toRefs(props);

const container = ref<HTMLDivElement>();

const product = ref<Schemas["Product"]>();
const isLoading = ref(true);

const { unitPrice } = useProductPrice(product);
const { isSellable } = useProductState(product);

const { stop } = useIntersectionObserver(
  container,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      stop();
      loadProductData();
    }
  },
  { rootMargin: "250px" },
);

// WORKAROUND since setting shopwareId as key in the parent
// v-for is not working, this line updates the product as needed
watch(shopwareId, loadProductData);

async function loadProductData() {
  isLoading.value = true;
  product.value = await search(shopwareId.value);
  isLoading.value = false;
}

const getCoverImage = computed(() => {
  return (
    product.value?.cover?.media || product.value?.media?.[0]?.media || null
  );
});
</script>

<template>
  <div ref="container" class="mb-3 bg-white rd-r">
    <template v-if="!product || (product && isLoading)">
      <div
        role="status"
        class="py-3 px-2 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
      >
        <div
          class="hidden sm:blockflex items-center w-90px h-60px bg-gray-300 rounded dark:bg-gray-700"
        ></div>
        <div class="w-full">
          <div
            class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"
          ></div>
          <div
            class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"
          ></div>
          <div
            class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"
          ></div>
          <div
            class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[500px]"
          ></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="b-l-5 b-1 py-3 px-3 rd-r"
        :class="[
          isSellable && unitPrice > 0 && product.availableStock
            ? 'b-scheppach-primary-500'
            : 'b-scheppach-error-300',
        ]"
      >
        <div class="flex items-start flex-col sm:flex-row justify-between">
          <NuxtLink
            :to="formatLink(getProductRoute(product))"
            class="flex gap-5 pr-5"
          >
            <SharedMediaImage
              :media="getCoverImage"
              :width="150"
              :height="90"
              class="w-[150px] h-[90px] object-contain sm:my-auto rd"
              alt="spare-part-image"
            />

            <div
              class="flex items-start flex-col sm:flex-row sm:flex-col sm:gap-5 md:gap-0 justify-center w-auto xl:w-85"
            >
              <div>
                <p
                  class="text-md font-700 c-scheppach-primary-500 line-clamp-2"
                >
                  {{ getTranslatedProperty(product, "name") }}
                </p>
                <p class="c-scheppach-neutral-400">
                  {{ t("product.articleNumber") }}: {{ product.productNumber }}
                </p>
              </div>
              <p
                class="mt-2 inline p-1 c-scheppach-primary-500 b b-scheppach-primary-500 rd px-2 bg-scheppach-primary-50"
              >
                {{ position }}
              </p>
            </div>
          </NuxtLink>

          <div
            v-if="isSellable && product.availableStock && unitPrice > 0"
            class="p-2 rd flex flex-row sm:flex-col justify-between flex-shrink-0 b-1 b-scheppach-primary-300 bg-scheppach-primary-50 w-full sm:w-auto mt-6 sm:mt-0"
          >
            <!-- Price -->
            <div class="flex items-center justify-between rd sm:mb-2">
              <SharedPrice
                :value="unitPrice"
                class="text-xl font-700 c-scheppach-primary-500 ml-2 sm:ml-0"
              >
              </SharedPrice>
            </div>

            <!-- Add To Cart -->
            <ProductAddToCart :product="product" :size="'small'" />
          </div>

          <div
            v-else
            class="flex items-center text-center b b-scheppach-error-500 bg-scheppach-error-50 c-scheppach-error-500 rd px-2 mt-5 sm:mt-0"
          >
            <span
              v-if="(!product.availableStock && isSellable) || unitPrice == 0"
            >
              {{ t("product.notAvailable") }}
            </span>
            <span v-else-if="!isSellable">
              {{ t("product.notSellable") }}
            </span>
          </div>
        </div>
        <div
          v-if="nextPossibleDeliveryDate && !product.availableStock"
          class="c-scheppach-error-500 inline-block pt-1 sm:px-2 mt-2 sm:mt-0 flex sm:justify-end"
        >
          <div class="flex items-center gap-1.5">
            <i class="i-sl-calendar-time inline-block size-5 mb--0.5s"></i>
            {{
              t("spareParts.nextPossibleDeliveryDate", {
                p: nextPossibleDeliveryDate,
              })
            }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
